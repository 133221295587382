
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { CustomerService } from '@/services/customer-service';
@Component
export default class AddEditEmail extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  @Prop() selectedEmails!: any[];
  @Prop() emailType!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  customerEmailList: any[] = [];
  emailToSave: string = '';
  /* Utility Functions */
  showDialog() {
    this.getEmailList();
    this.dialog = true;
  }
  closeDialog() {
    this.emailToSave = '';
    this.dialog = false;
  }
  async getEmailList() {
    const response = await CustomerService.GetEmailList();
    this.customerEmailList = response.data.map((r: any) => r.EmailAddress);
  }
  hasEmailListChanged() {
    return this.emailToSave && this.validateEmail(this.emailToSave);
  }
  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(re.test(email.toLowerCase()));
    return re.test(email.toLowerCase());
  }
  enterPressed() {
    setTimeout(() => {
      console.log('enter pressed');
      let dialog = document.getElementById('addEmailDialog');
      if (dialog) dialog.focus();
    }, 100);
  }
  save() {
    if (this.hasEmailListChanged()) {
      this.$emit('EmailListChanged', this.emailToSave);
      this.closeDialog();
    }
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
