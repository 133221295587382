
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BrandEditDialog from '@/components/BrandEditDialog.vue';
import BrandAddDialog from '@/components/BrandAddDialog.vue';
import { Brand } from '@/store/models/ItemModel';
import { CustomerService } from '@/services/customer-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: { BrandEditDialog, BrandAddDialog }
})
export default class Brands extends Vue {
  $refs!: {
    BrandEditDialog: HTMLFormElement;
    BrandAddDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  brands: Brand[] = [];
  /* Async Functions */
  /* Utility Functions */
  OpenBrandDeleteDialog(brand: Brand) {
    Confirm(
      () => {
        this.DeleteBrand(brand);
      },
      'Delete Brand',
      'Are you sure you want to delete this brand?'
    );
  }
  OpenBrandEditDialog() {
    this.$refs.BrandEditDialog.openDialog();
  }
  OpenBrandAddDialog() {
    this.$refs.BrandAddDialog.openDialog();
  }
  SetBrands(brands: Brand[]) {
    this.brands = brands;
    this.GetBrandLogos();
  }
  async DeleteBrand(brand: Brand) {
    if (this.brands.length <= 1) {
      SetSnackBar('You must have at least one brand associated with your account');
      return;
    }
    try {
      await CustomerService.DeleteBrand(brand.Id);
      SetSnackBar('Deleted brand successfully');
      this.$emit('refreshCustomer');
    } catch (error) {
      SetSnackBar(
        'There was an issue deleting your brand. Please contact techsupport@just1label to get your issue resolved'
      );
    }
  }
  /* Loaders */
  async GetBrandLogos() {
    for (let brand of this.brands) {
      const { data } = await CustomerService.GetBrandLogo(brand.Id);
      brand.Src = data;
    }
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
