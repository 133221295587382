
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import PaymentTerms from '@/components/PaymentTerms.vue';
import Brands from '@/components/Brands.vue';
import AddEditEmail from '@/components/AddEditEmail.vue';
import BillingAddressFormatter from '@/components/formatterComponents/BillingAddressFormatter.vue';
import AddEditBillingAddress from '@/components/addressComponents/AddEditBillingAddress.vue';
import AddEditShippingAddress from '@/components/AddEditShippingAddress.vue';
import { CustomerService } from '@/services/customer-service';
import { DisableFormAutofill } from '@/helpers/WebHelper';
import { CloneWithoutReference } from '@/helpers/ObjectHelper';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { ConstValues } from '@shared/ConstValues';
import {
  CompanyEmails,
  ContactModel,
  EmailNotificationTypes
} from '@/store/models/CompanyModel';
import { Address, Country, ShippingVendor } from '@/store/models/AddressModel';
import ShippingService from '@/services/shipping-service';
import { ShippingVendorModel } from '@/store/models/RequestModels/ShippingRequestModels';
import AddShippingVendorDialog from '@/components/AddShippingVendorDialog.vue';
import { CustomerShippingVendor } from '@/store/models/ShippingModels';
import { VDataTableHeader } from '@/store/models/VDataTableModel';
import { AddUser } from '@/store/models/ProfileModel';
import { Brand } from '@/store/models/ItemModel';
import AuthService from '@/services/auth-service';
import { Confirm } from '@/helpers/AuthHelper';
import { PaymentService } from '@/services/payment-service';
@Component({
  components: {
    DefaultLayout,
    PaymentTerms,
    AddEditEmail,
    BillingAddressFormatter,
    AddEditBillingAddress,
    Brands,
    AddShippingVendorDialog,
    AddEditShippingAddress
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    AddEditEmail: HTMLFormElement;
    AddEditBillingAddress: HTMLFormElement;
    AddShippingVendorDialog: HTMLFormElement;
    AddEditShippingAddress: HTMLFormElement;
    EditBrandDialog: HTMLFormElement;
    Brands: HTMLFormElement;
    WalletTab: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Mutation('setCustomer', { namespace: 'customer' }) setCustomer: any;
  /* Watchers */
  /* Data */
  customer: any = null;
  defaultUser: AddUser = new AddUser();
  dialog: boolean = false;
  shouldOpenAdvancedOptionsDialog: boolean = false;
  shouldOpenOptionsDialog: boolean = false;
  isLoadingCustomer: boolean = false;
  isLoading: boolean = false;
  isSavingCustomerDetail: boolean = false;
  isSavingContactInfo: boolean = false;
  isAdvancedOptionsFormValid: boolean = false;
  isOptionsFormValid: boolean = false;
  isUserFormValid: boolean = false;
  isCreatingUser: boolean = false;
  shouldShowContactDialog: boolean = false;
  shouldShowAlternateAddresses: boolean = false;
  selectedEmails: string[] = [];
  emailToRemove: string = '';
  emailType: string = '';
  deleteType: string = '';
  contactEntityType: string = '';
  contactEntityValue: string = '';
  contactEntityId: string = '';
  billingAddressOptions: string[] = ['Edit', 'Delete'];
  rollDirectionOptions: any = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    }
  ];
  rollSpecificationOptions: any = [
    {
      src: require('@/assets/standarddiameter.png'),
      title: 'Maximum labels per roll',
      description:
        'Best for machine application.  Produces roll diameters up to 12 inches'
    }
  ];
  selectedRollSpecification: any = null;
  selectedRollDirection: any = null;
  rollDirectionToEdit: any = null;
  customerOptionsToEdit: any = {};
  billingAddressToEdit: any = null;
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  users: any[] = [];
  emails: any[] = [];
  emailRules: any = [
    (v: any) =>
      !v ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      'E-mail must be valid'
  ];
  tab: any = null;
  items: any = ['Contact', 'Payment', 'Shipping', 'Advanced'];
  shippingVendors: ShippingVendor[] = [];
  newCustomerShippingVendor: ShippingVendorModel = new ShippingVendorModel();
  customerShippingVendors: CustomerShippingVendor[] = [];
  isLoadingShippingVendors: boolean = false;
  isLoadingCustomerShippingVendors: boolean = false;
  showPassword: boolean = false;
  userTableColumns: VDataTableHeader[] = [
    {
      name: 'Name',
      text: 'Name',
      sortable: false,
      value: 'Name'
    },
    {
      name: 'UserName',
      text: 'User Name / Email',
      sortable: false,
      value: 'UserName'
    },
    {
      name: 'LastActivityDate',
      text: 'Last Activity Date',
      sortable: false,
      value: 'LastActivityDate'
    },
    {
      name: 'IsLocked',
      text: '',
      sortable: false,
      value: 'IsLocked'
    }
  ];
  /* Methods */

  async SaveUser() {
    try {
      this.isCreatingUser = true;
      console.log(this.defaultUser);
      const checkForExistingAccountReturn = await AuthService.hasAnIdentityAccount(
        this.defaultUser.UserName
      );
      if (checkForExistingAccountReturn.data) {
        SetSnackBar('This email is already associated with an account in the system.');
        this.isCreatingUser = false;
      } else {
        let formData = new FormData();
        formData.append('Email', this.defaultUser.UserName);
        formData.append('Password', this.defaultUser.Password);
        const createUserIdentityReturn = await AuthService.createUserForAdmin(formData);
        let model = {
          FirstName: this.defaultUser.FirstName,
          LastName: this.defaultUser.LastName,
          UserName: this.defaultUser.UserName,
          Password: this.defaultUser.Password,
          ShouldSendPasswordEmail: this.defaultUser.ShouldSendCredEmail
        };
        const createUserCustomerService = await CustomerService.AddUser(model);
        SetSnackBar('This user has been created.');
        this.loadUsers();
        this.isCreatingUser = false;
        this.dialog = false;
      }
    } catch (err) {
      SetSnackBar(
        'Something went wrong while creating this account.  Please contact technical support for assistant.'
      );
    }
  }
  closeDialog() {
    this.defaultUser = new AddUser();
    this.dialog = false;
  }
  hasAdvancedOptionsChanged() {
    return (
      this.customer &&
      this.rollDirectionToEdit.value !== this.customer.Options.RollDirection.Position
    );
  }
  hasBillingAddressChanged() {
    return (
      this.customer &&
      Object.entries(this.customerOptionsToEdit.BillingAddress).toString() ===
        Object.entries(this.customer.BillingAddress).toString()
    );
  }
  async loadCustomer() {
    this.isLoadingCustomer = true;
    try {
      const response = await CustomerService.GetCurrentCustomer();
      this.customer = { ...response.data };
      this.customer.Brands = this.customer.Brands.map((c: any) => new Brand(c));
      this.setCustomer(this.customer);
      this.customerOptionsToEdit = CloneWithoutReference(this.customer);
      this.selectedRollDirection = this.rollDirectionOptions.find(
        (r: any) => r.value == this.customer.Options.RollDirection.Position
      );
      this.rollDirectionToEdit = this.rollDirectionOptions.find(
        (r: any) => r.value == this.customer.Options.RollDirection.Position
      );
      this.SetBrands();
    } catch (err) {
      console.log(err);
      SetSnackBar('There was an error grabbing your company profile');
    }
    this.isLoadingCustomer = false;
  }
  async GetStates() {
    const response = await CustomerService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const { data: countries } = await CustomerService.GetCountries();
    this.countries = countries;
  }
  async GetEmailList() {
    const { data: emails } = await CustomerService.GetEmailList();
    this.emails = emails;
  }

  OpenDeleteEmailConfirmationDialog(
    emailList: string[],
    emailToRemove: string,
    emailType: string
  ) {
    this.selectedEmails = emailList;
    this.emailType = emailType;
    this.emailToRemove = emailToRemove;
    this.deleteType = 'email';
    Confirm(
      () => {
        this.RemoveEmailFromList();
      },
      `Remove from ${emailType} notifications`,
      `Are you sure you want to remove ${emailToRemove} from receiving ${emailType} notifications?`
    );
  }
  RemoveEmailFromList() {
    const indexToRemove = this.selectedEmails.findIndex(e => e === this.emailToRemove);
    this.selectedEmails.splice(indexToRemove, 1);
    this.UpdateNewEmailList(this.selectedEmails);
  }
  AddEmailToList(email: string) {
    this.selectedEmails.push(email);
    this.UpdateNewEmailList(this.selectedEmails);
  }
  async UpdateNewEmailList(emailList: any[]) {
    SetSnackBar('Updating your E-mail Notification list...');
    this.selectedEmails = emailList;
    let model = new CompanyEmails({
      InvoiceNotificationEmails: this.customer.InvoiceNotificationEmails,
      OrderNotificationEmails: this.customer.OrderNotificationEmails,
      ShippingNotificationEmails: this.customer.ShippingNotifications
    });
    switch (this.emailType) {
      case 'Invoice': {
        model.InvoiceNotificationsEmails = emailList;
        model.EmailType = EmailNotificationTypes.Invoice;
        break;
      }
      case 'Order': {
        model.OrderNotificationEmails = emailList;
        model.EmailType = EmailNotificationTypes.OrderPlaced;
        break;
      }
      case 'Shipping': {
        model.ShippingNotificationEmails = emailList;
        model.EmailType = EmailNotificationTypes.Shipping;
        break;
      }
    }
    try {
      await CustomerService.UpdateNotificationEmails(model);
      this.loadCustomer();
      SetSnackBar('Your E-mail Notification list has been updated.');
    } catch (e) {
      SetSnackBar(
        'Something went wrong when updating your E-mail Notification list. Please contact techsupport@just1label.com'
      );
    }
  }
  async saveContactInfo() {
    switch (this.contactEntityType) {
      case 'Contact Name': {
        await this.UpdateContactName();
        break;
      }
      case 'Contact Email': {
        await this.UpdateContactEmail();
        break;
      }
      case 'Primary Phone': {
        await this.UpdatePrimaryPhone();
        break;
      }
    }
    this.loadCustomer();
    this.closeContactDialog();
  }
  async UpdateContactName() {
    this.isSavingContactInfo = true;
    try {
      const model = new ContactModel({ ContactName: this.contactEntityValue });
      await CustomerService.UpdateContactName(model);
    } catch (err) {
      SetSnackBar(
        'Something went wrong when updating your Contact Name. Please contact techsupport@just1label.com'
      );
    }
    this.isSavingContactInfo = false;
  }
  async UpdateContactEmail() {
    this.isSavingContactInfo = true;
    try {
      const model = new ContactModel({ EmailAddress: this.contactEntityValue });
      await CustomerService.UpdateContactEmail(model);
    } catch (err) {
      SetSnackBar(
        'Something went wrong when updating your Contact E-mail. Please contact techsupport@just1label.com'
      );
    }
    this.isSavingContactInfo = false;
  }
  async UpdatePrimaryPhone() {
    this.isSavingContactInfo = true;
    try {
      const model = new ContactModel({
        PhoneNumber: this.contactEntityValue,
        PhoneId: this.contactEntityId
      });
      await CustomerService.UpdatePrimaryPhone(model);
    } catch (err) {
      SetSnackBar(
        'Something went wrong when updating your Primary Phone Number. Please contact techsupport@just1label.com'
      );
    }
    this.isSavingContactInfo = false;
  }
  async UpdateRollDirection() {
    this.isLoading = true;
    try {
      const response = await CustomerService.UpdateCustomerOptionRollDirection(
        this.rollDirectionToEdit.value
      );
      this.shouldOpenAdvancedOptionsDialog = false;
      this.loadCustomer();
      SetSnackBar('Updated Roll Direction Successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error updating your roll direction. Please contact technical support'
      );
    }
    this.isLoading = false;
  }
  async UpdateRetentionAddress(model: any) {
    try {
      await CustomerService.UpdateRetentionAddress(model);
      this.loadCustomer();
      SetSnackBar('Changed retention address successfully');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  async UpdateAddress() {
    this.isSavingCustomerDetail = true;
    try {
      if (this.customerOptionsToEdit.BillingAddress.CountryValue === 'USA')
        this.customerOptionsToEdit.BillingAddress.Country = new Country({
          Id: 1
        });
      let model = ConversionHelper.convertAddressToAddressModel(
        this.customerOptionsToEdit.BillingAddress,
        false,
        true,
        true
      );
      await CustomerService.UpdateAddress(model);
      SetSnackBar('Updated Address Successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error updating your billing address. Please contact technical support'
      );
    }
    this.loadCustomer();
    this.shouldOpenOptionsDialog = false;
    this.isSavingCustomerDetail = false;
  }
  UpdateCustomerOD() {
    if (!(this.customer.OD >= 5 && this.customer.OD <= 14)) {
      Confirm(
        () => {},
        'Invalid entry',
        'Outer diameter must be between 5 and 14',
        '',
        'Close'
      );
      return;
    }
    try {
      Confirm(
        async () => {
          await CustomerService.UpdateCustomerOD(this.customer.OD);
          this.loadCustomer();
          SetSnackBar('Outer diameter changed successfully');
        },
        'Change default out diameter',
        'Are you sure you want to change your default maximum outer diameter? This will affect all future orders that are scheduled for pick up.'
      );
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  async UpdateCustomerProfile() {
    this.isSavingCustomerDetail = true;
    let model = ConversionHelper.convertToCompanyModel(this.customerOptionsToEdit);
    model.InvoiceNotificationsEmails = this.customerOptionsToEdit.InvoiceNotificationEmails.map(
      (e: any) => e.email || e
    );
    model.AmazonAuthenticationEmails = this.customerOptionsToEdit.AmazonAuthenticationEmails.map(
      (e: any) => e.email || e
    );
    model.OrderNotificationEmails = this.customerOptionsToEdit.OrderNotificationEmails.map(
      (e: any) => e.email || e
    );
    try {
      await CustomerService.UpdateCompanyProfileDetail(model);
    } catch (err) {
      SetSnackBar(
        'There was an error updating your company profile. Please contact technical support'
      );
    }
    this.loadCustomer();
    this.shouldOpenOptionsDialog = false;
    this.isSavingCustomerDetail = false;
  }
  disableChromeAutofill() {
    DisableFormAutofill('company-profile-form');
  }
  openOptionsDialog() {
    this.shouldOpenOptionsDialog = true;
    setTimeout(() => {
      this.disableChromeAutofill();
    }, 100);
  }
  async updateAddressDefault(address: any) {
    try {
      await CustomerService.UpdateAddressDefault(address.Id, true);
      SetSnackBar('Updated Address Default Successfully');
      this.loadCustomer();
    } catch (err) {
      SetSnackBar(
        'Error updating address default, please contact techsupport@just1label.com to get this resolved'
      );
    }
  }
  openEditBillingAddressDialog(address: any) {
    this.billingAddressToEdit = address;
    this.$refs.AddEditBillingAddress.openAddressDialog();
  }
  openRetentionAddressDialog() {
    this.$refs.AddEditShippingAddress.openAddressDialog();
  }
  openAddEditEmailDialog(emailList: any[], emailType: string) {
    this.selectedEmails = emailList;
    this.emailType = emailType;
    this.$refs.AddEditEmail.showDialog();
  }
  closeOpenOptionsDialog() {
    this.shouldOpenOptionsDialog = false;
    this.customerOptionsToEdit = CloneWithoutReference(this.customer);
  }
  openContactDialog(contactEntityType: string) {
    switch (contactEntityType) {
      case 'Contact Name': {
        this.contactEntityValue = this.customer.Contact ? this.customer.Contact : '';
        break;
      }
      case 'Contact Email': {
        this.contactEntityValue = this.customer.ContactEmail
          ? this.customer.ContactEmail.EmailAddress
          : '';
        break;
      }
      case 'Primary Phone': {
        this.contactEntityValue = this.customer.Phone
          ? this.customer.Phone.PhoneNumber
          : '';
        this.contactEntityId = this.customer.Phone ? this.customer.Phone.PhoneId : '';
        break;
      }
    }
    this.contactEntityType = contactEntityType;
    this.shouldShowContactDialog = true;
  }
  closeContactDialog() {
    this.contactEntityType = '';
    this.contactEntityValue = '';
    this.shouldShowContactDialog = false;
  }
  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  async saveCustomerShippingVendor() {
    try {
      await ShippingService.SaveCustomerShippingVendor(this.newCustomerShippingVendor);
      this.getCustomerShippingVendors();
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }

  editShippingVendor(vendor: CustomerShippingVendor) {
    this.$refs.AddShippingVendorDialog.openDialog(vendor);
  }
  deleteShippingVendor(shippingVendor: CustomerShippingVendor) {
    try {
      Confirm(
        async () => {
          await ShippingService.DeleteCustomerShippingVendor(shippingVendor.Id);
          this.getCustomerShippingVendors();
          SetSnackBar('Successfully removed customer shipping address');
        },
        'Delete Shipping Account',
        'Are you sure you want to delete this shipping account?'
      );
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }

  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  /* Loaders */
  async getShippingVendors() {
    try {
      const { data } = await ShippingService.GetShippingVendors();
      this.shippingVendors = data;
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  async getCustomerShippingVendors() {
    try {
      const { data } = await ShippingService.GetCustomerShippingVendors();
      this.customerShippingVendors = data.map((d: any) => new CustomerShippingVendor(d));
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  loadShippingVendors() {
    this.getShippingVendors();
    this.getCustomerShippingVendors();
  }
  async loadUsers() {
    const data = await CustomerService.GetCustomerUsers();
    this.users = data.data;
    this.users.forEach(async (u: any) => {
      try {
        const response = await AuthService.isUserLockedout(u.UserName);
        u.IsLocked = response.data.isUserLockedOut;
      } catch (error) {
        let err = error;
        u.IsLocked = true;
      }
    });
  }
  SetBrands() {
    setTimeout(() => {
      if (this.$refs.Brands) this.$refs.Brands.SetBrands(this.customer.Brands);
    }, 500);
  }
  /* Mounted */
  async mounted() {
    this.selectedRollSpecification = this.rollSpecificationOptions[0];
    await this.loadCustomer();
    this.GetStates();
    this.GetCountries();
    this.GetEmailList();
    if (this.$route.query.wallet) {
      setTimeout(async () => {
        this.$refs.WalletTab.$el.click();
      }, 100);
    }
  }
  /* Created */
}
